<script>
import assign from 'lodash/assign';
import chunk from 'lodash/chunk';
import first from 'lodash/first';
import map from 'lodash/map';
import range from 'lodash/range';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { BUTTON_TYPES, COLORS, ICONS } from '@emobg/vue-base';
import { MuiSelect, MuiValidationWrapper } from '@emobg/motion-ui/v1';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent } from '@/components';
import { SAP_STATUS } from '@domains/Invoicing/Invoices/Invoice/Sap/const/sap.const';

export default {
  name: 'SapExportFormComponent',

  components: {
    CancelButton,
    GenericModalComponent,
    MuiSelect,
    MuiValidationWrapper,
  },

  inject: ['$labels'],
  data() {
    return {
      isLoading: false,
      inputs: {
        year: undefined,
        month: null,
      },
      isDownloadableErrorVisible: false,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperator: userState => userState.operators.active,
    }),
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      availableFiles: state => state.sapList.data,
    }),
    yearsToShow() {
      const actualYear = moment().year();
      return range(actualYear, actualYear - 10);
    },
    months() {
      return map(moment.months(), (month, index) => ({
        label: month,
        value: index + 1,
        isDisabled: this.isMonthDisabled(index),
      }));
    },
    isExportable() {
      return isNull(this.inputs.month);
    },
    columnsWithMonths() {
      return chunk(this.months, 6);
    },
  },
  watch: {
    inputs: {
      handler(newInputData) {
        const data = assign({ cs_operator_uuid: this.activeOperator.uuid }, newInputData);
        const foundData = this.confirmDownload(data);
        this.isDownloadableErrorVisible = foundData && foundData.status === SAP_STATUS.ready;
      },
      deep: true,
    },
  },
  async created() {
    this.inputs.year = first(this.yearsToShow);
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.COLORS = COLORS;
    this.ICONS = ICONS;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, [
      'requestExportSapFile',
      'getSapFilesList',
    ]),
    async requestSapExport() {
      this.isLoading = true;
      const data = {
        operatorUuid: this.activeOperator.uuid,
        query: {
          month: Number(this.inputs.month),
          year: Number(this.inputs.year),
        },
      };
      await this.requestExportSapFile(data);
      await this.getSapFilesList(this.activeOperator.uuid);
      this.isLoading = false;
      this.closeModal();
    },
    confirmDownload(sapData) {
      const filteredFiles = filter(this.availableFiles, file => (file.year === sapData.year && file.month === Number(sapData.month)));
      return first(filteredFiles);
    },
    closeModal() {
      this.$emit('closeModal');
    },
    isMonthDisabled(monthNumber) {
      const currentYear = moment().year();
      const currentMonth = moment().month();
      return currentYear === this.inputs.year && (monthNumber >= currentMonth);
    },
    resetMonthSelected() {
      this.inputs.month = null;
    },
  },
};
</script>

<template>
  <ui-form @submitform="requestSapExport">
    <MuiValidationWrapper>
      <template slot-scope="{ areAllValid }">
        <GenericModalComponent
          v-bind="$attrs"
          :header="{ isClosable: true }"
          :size="SIZES.small"
          title="SAP Export"
          data-test-id="sap-export-modal"
          @modal-closed="closeModal"
        >
          <template #body>
            <div class="row">
              <div class="col-12">
                <MuiSelect
                  v-model="inputs.year"
                  :options="yearsToShow"
                  :size="SIZES.medium"
                  name="year"
                  class="w-100"
                  label="Select Year and Month*"
                  data-test-id="sap-export-modal-year-select"
                  required
                  @change="resetMonthSelected"
                />
              </div>
              <div class="col-12 mt-3">
                <div class="row">
                  <div
                    v-for="(column, key) in columnsWithMonths"
                    :key="key"
                    class="col-6 d-flex flex-column"
                  >
                    <ui-radio
                      v-for="month in column"
                      :key="month.label"
                      :value="inputs.month"
                      :caption="month.label"
                      :option="`${month.value}`"
                      :disabled="month.isDisabled"
                      class="mb-3"
                      name="month"
                      @changevalue="() => inputs.month = month.value"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="isDownloadableErrorVisible"
                class="w-100"
              >
                <ui-alert
                  :color="COLORS.danger"
                  :icon="ICONS.alertFull"
                >
                  Selecting this month will overwrite current exported file
                </ui-alert>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="d-flex align-content-center">
              <CancelButton
                :disabled="isLoading"
                data-test-id="sap-export-modal-cancel-button"
                @click="closeModal"
              />
              <ui-button
                :disabled="!areAllValid || isExportable"
                :loading="isLoading"
                :size="SIZES.small"
                :type="BUTTON_TYPES.submit"
                data-test-id="sap-export-modal-export-button"
              >
                {{ $labels.Common.Actions.export }}
              </ui-button>
            </div>
          </template>
        </GenericModalComponent>
      </template>
    </MuiValidationWrapper>
  </ui-form>
</template>
