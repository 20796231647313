import { sentenceCase } from '@emobg/web-utils';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import DownloadSapFileCell from '@domains/Invoicing/Invoices/Invoice/Sap/components/DownloadSapFileCell';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export const SAP_STATUS = {
  ready: 'ready',
  pending: 'pending',
  failed: 'failed',
};

const SAP_STATUS_COLORS = {
  [SAP_STATUS.ready]: COLORS.success,
  [SAP_STATUS.pending]: GRAYSCALE.ground,
  [SAP_STATUS.failed]: COLORS.danger,
};

export const SAP_SCHEMA = openSapModal => ([
  {
    title: 'Year and month',
    property: 'name',
  },
  {
    title: 'Status',
    property: 'status',
    component: BadgeComponent,
    props: ({ status }) => ({
      text: sentenceCase(status),
      color: SAP_STATUS_COLORS[status],
    }),
  },
  {
    title: 'Download',
    component: DownloadSapFileCell,
    props: result => ({
      callback: openSapModal,
      isVisible: result.status === SAP_STATUS.ready,
      sapFileUuid: result.uuid,
    }),
  },
]);
