var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "DownloadSapFile" }, [
    _vm.isVisible
      ? _c(
          "a",
          {
            staticClass: "cursor-pointer",
            on: { click: _vm.exportSelectedMonth },
          },
          [_c("ui-icon", { attrs: { icon: _vm.ICONS.download } })],
          1
        )
      : _c("p", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }