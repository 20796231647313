<template>
  <div class="DownloadSapFile">
    <a
      v-if="isVisible"
      class="cursor-pointer"
      @click="exportSelectedMonth"
    >
      <ui-icon :icon="ICONS.download" />
    </a>
    <p v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

export default {
  props: {
    callback: {
      type: Function,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    sapFileUuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => state.operators.active.uuid,
    }),
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      sapDownloadData: state => state.downloadSap.data,
    }),
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, [
      'downloadSapFile',
      'getSapFilesList',
    ]),
    async exportSelectedMonth() {
      const downloadParams = {
        operatorUuid: this.activeOperatorUuid,
        sapFileUuid: this.sapFileUuid,
      };
      await this.downloadSapFile(downloadParams);
      if (this.sapDownloadData) {
        const link = document.createElement('a');
        link.href = this.sapDownloadData.url;
        link.download = `SAP-${this.sapDownloadData.name}`;
        link.click();
      }
    },
  },
};
</script>
